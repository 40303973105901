<template>
  <div class="turnier-form-container container" v-if="Object.keys(turnier) != 0">
    <transition appear name="fadeRight" mode="out-in">
      <form @submit.prevent="validateForm" id="edit-turnier-form">
        <md-steppers :md-active-step.sync="active" md-alternative>
          <md-step id="first" :md-error="firstStepError" :md-done.sync="first">
            <div class="card">
              <md-field class="md-field-icon" :class="getValidationClass('turnierName')">
                <i class="fas fa-beer"></i>
                <label>{{lang.turnierForm.labelTurniername}}</label>
                <md-input @blur="$v.turnier.turnierName.$touch()" :name="lang.turnierForm.labelTurniername" v-model="turnier.turnierName" :maxlength="$v.turnier.turnierName.$params.maxLength.max" type="text"></md-input>
                <md-icon v-if="isFieldInvalid('turnierName')" class="color-invalid">close</md-icon>
                <span class="md-error" v-if="!$v.turnier.turnierName.required">{{lang.turnierForm.turnierNameRequired}}</span>
                <span class="md-error" v-else-if="!$v.turnier.turnierName.minLength || !$v.turnier.turnierName.maxLength ">{{$v.turnier.turnierName.$params.minLength.min}} - {{$v.turnier.turnierName.$params.maxLength.max}} {{lang.turnierForm.turnierNameMinLength}}</span>
              </md-field>
              <md-datepicker @blur="$v.turnier.datum.$touch()" :name="lang.turnierForm.labelDatum" :md-disabled-dates="disabledDates" v-model="turnier.datum">
                <label>{{lang.turnierForm.labelDatum}}</label>
              </md-datepicker>
              <md-field class="md-field-icon" :class="getValidationClass('startZeit')">
                <i class="fas fa-beer"></i>
                <label>{{lang.turnierForm.labelStartzeit}}</label>
                <md-input @blur="$v.turnier.startZeit.$touch()" :name="lang.turnierForm.labelStartzeit" v-model="turnier.startZeit" type="time"></md-input>
                <span class="md-error" v-if="!$v.turnier.startZeit.required">{{lang.turnierForm.startZeitRequired}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('stadt')">
                <i class="fas fa-map-marked-alt"></i>
                <label>{{lang.turnierForm.labelVeranstaltungsort}}</label>
                <md-input @blur="$v.turnier.stadt.$touch()" :name="lang.turnierForm.labelVeranstaltungsort" v-model.trim="turnier.stadt" type="text"></md-input>
                <span class="md-error" v-if="!$v.turnier.stadt.required">{{lang.turnierForm.stadtRequired}}</span>
              </md-field>
              <md-field class="md-field-icon">
                <i class="fas fa-unlock"></i>
                <label>{{lang.turnierForm.labelPasswort}}</label>
                <md-input :name="lang.turnierForm.labelPasswort" v-model="turnier.passwort" type="password" autocomplete="off"></md-input>
              </md-field>
              <md-field class="md-field-icon">
                <i class="fas fa-spell-check"></i>
                <label>{{lang.turnierForm.labelBeschreibung}}</label>
                <md-textarea :name="lang.turnierForm.labelBeschreibung" v-model="turnier.beschreibung"></md-textarea>
              </md-field>
            </div>
            <md-button class="md-raised md-primary md-filled" @click="setDone('first', 'second')">{{lang.createTurnier.buttonNext}}</md-button>
          </md-step>
          <md-step id="second" :md-error="secondStepError" :md-done.sync="second">
            <div class="card">
              <p class="not-editable-message" v-if="!isTurnierNotStarted">{{lang.editTurnier.notEditableMessage}}</p>
              <md-field class="md-field-icon" :class="getValidationClass('anzTische')">
                <i class="fas fa-table-tennis"></i>
                <label>{{lang.turnierForm.labelTische}}</label>
                <md-input :disabled="!isTurnierNotStarted" @blur="$v.turnier.anzTische.$touch()" :name="lang.turnierForm.labelTische" v-model.number="turnier.anzTische" step="1" :min="$v.turnier.anzTische.$params.minValue.min" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('anzTische')" class="color-invalid">close</md-icon>
                <span class="md-error" v-if="!$v.turnier.anzTische.required">{{lang.turnierForm.anzTischeRequired}}</span>
                <span class="md-error" v-else-if="!$v.turnier.anzTische.minValue">{{$v.turnier.anzTische.$params.minValue.min}} {{lang.turnierForm.anzTischeMinValue}}</span>
              </md-field>
              <md-field class="md-field-icon" :class="getValidationClass('anzTeams')">
                <i class="fas fa-users"></i>
                <label>{{lang.turnierForm.labelTeams}}</label>
                <md-input :disabled="!isTurnierNotStarted" @blur="$v.turnier.anzTeams.$touch()" :name="lang.turnierForm.labelTeams" v-model.number="turnier.anzTeams" step="1" :min="$v.turnier.anzTeams.$params.between.min" :max="$v.turnier.anzTeams.$params.between.max" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('anzTeams')" class="color-invalid">close</md-icon>
                <span class="md-error" v-if="!$v.turnier.anzTeams.required">{{lang.turnierForm.anzTeamsRequired}}</span>
                <span class="md-error" v-else-if="!$v.turnier.anzTeams.between">{{$v.turnier.anzTeams.$params.between.min}} - {{$v.turnier.anzTeams.$params.between.max}} {{lang.turnierForm.anzTeamsBetweenValue}}</span>
              </md-field>
              <p class="p-unstyled stepper-text"><strong>{{lang.turnierForm.labelSpielModusEmpfehlung}}</strong></p>
              <p class="p-unstyled stepper-text" :class="spielmodusEmpfehlung === lang.turnierForm.spielModusNull ? 'font-color-lightgray' : ''">
                {{spielmodusEmpfehlung}}
              </p>
              <md-switch :disabled="!isTurnierNotStarted" v-model="isGruppensystem" :class="isGruppensystem ? 'font-color-secondary' : 'font-color-primary'" > {{isGruppensystem ? lang.turnierForm.spielModusGruppen : lang.turnierForm.spielModusKO}} </md-switch>
              <p v-if="!isGruppensystem">{{lang.turnierForm.labelSpieleKOTeam}}: {{anzSingleSpieleTeam}}</p>
              <p v-if="!isGruppensystem">{{lang.turnierForm.labelSpieleKOAll}}: {{anzSingleSpiele}}</p>
              <md-field v-if="isGruppensystem" class="md-field-icon" :class="getValidationClass('anzGruppen')">
                <i class="fas fa-people-arrows"></i>
                <label>{{lang.turnierForm.labelGruppen}}</label>
                <md-input :disabled="!isTurnierNotStarted" @blur="$v.turnier.anzGruppen.$touch()" :name="lang.turnierForm.labelGruppen" v-model.number="turnier.anzGruppen" step="1" :min="$v.turnier.anzGruppen.$params.minValue.min" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('anzGruppen')" class="color-invalid">close</md-icon>
                <span class="md-helper-text">
                {{lang.turnierForm.labelEmpfehlungGruppen}} {{anzSpielerProGruppeEmpfehlung}} <br>
                {{lang.turnierForm.labelSpielerProGruppe}} {{anzSpielerProGruppe}} <br>
                {{lang.turnierForm.labelSpieleGruppenphase}} {{anzSpieleProGruppe}}</span>
                <span class="md-error" v-if="!$v.turnier.anzGruppen.required">{{lang.turnierForm.anzGruppenRequired}}</span>
                <span class="md-error" v-else-if="!$v.turnier.anzGruppen.minValue">{{$v.turnier.anzGruppen.$params.minValue.min}} {{lang.turnierForm.anzGruppenMinValue}}</span>
              </md-field>
              <md-checkbox v-if="isGruppensystem" :disabled="!isTurnierNotStarted" :name="lang.turnierForm.labelGruppenPhaseZweiterWeiter" v-model="turnier.gruppenPhaseZweiterWeiter">{{lang.turnierForm.labelGruppenPhaseZweiterWeiter}}</md-checkbox>
            </div>
            <md-button class="md-raised md-primary md-filled" @click="setDone('second', 'third')">{{lang.createTurnier.buttonNext}}</md-button>
          </md-step>
          <md-step class="spielzeiten-step" id="third" :md-error="thirdStepError" :md-done.sync="third">
            <p class="p-unstyled stepper-text">{{lang.turnierForm.hinweisTextZeit}}</p>
            <div>
              <p class="p-unstyled stepper-text">{{lang.turnierForm.spielZeit}}: {{gesamtSpielzeit}}<md-button @click="showHinweis()" class="asterisk">*</md-button></p>
            </div>
            <div class="card">
              <md-field class="md-field-icon" :class="getValidationClass('pausenZeiten')">
                <i class="fas fa-clock"></i>
                <label>{{lang.turnierForm.labelPausenzeiten}}</label>
                <md-input @blur="$v.turnier.pausenZeiten.$touch()" :name="lang.turnierForm.labelPausenzeiten" v-model.number="turnier.pausenZeiten" step="1" :min="$v.turnier.pausenZeiten.$params.minValue.min" type="number"></md-input>
                <md-icon v-if="isFieldInvalid('pausenZeiten')" class="color-invalid">close</md-icon>
                <span class="md-helper-text">{{lang.turnierForm.labelZeitEinheit}}</span>
                <span class="md-error" v-if="!$v.turnier.pausenZeiten.required">{{lang.turnierForm.pausenZeitenRequired}}</span>
                <span class="md-error" v-else-if="!$v.turnier.pausenZeiten.minValue">{{$v.turnier.pausenZeiten.$params.minValue.min}} {{lang.turnierForm.pausenZeitenMinValue}}</span>
              </md-field>
              <div v-for="spielArt in $v.turnier.spielArtTurnier.$each.$iter"
                  :key="spielArt.spielArtTurnierSchluessel.spielArtID.$model">
                <md-field class="md-field-icon" 
                  :class="spielArt.spielZeit.$dirty && spielArt.spielZeit.$invalid ? 'md-invalid' : ''"
                  v-if="(turnier.spielmodus == SPIELMODUS.KO_SYSTEM.name && spielArt.spielArt.spielArtEnum.$model == SPIELART.EINZELSPIEL.name) ||
                        (turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name && anzTeamsFirstFinalGame >= SPIELART[spielArt.spielArt.spielArtEnum.$model].countTeams && spielArt.spielArt.spielArtEnum.$model != SPIELART.EINZELSPIEL.name)"
                >
                  <i class="fas fa-clock"></i>
                  <label>{{lang.turnierForm.labelSpielZeit}} {{SPIELART[spielArt.spielArt.spielArtEnum.$model] ? lang.spielart[spielArt.spielArt.spielArtEnum.$model] : ''}}</label>
                  <md-input @blur="spielArt.spielZeit.$touch()" :name="lang.turnierForm.labelSpielZeit + ' ' + SPIELART[spielArt.spielArt.spielArtEnum.$model] ? lang.spielart[spielArt.spielArt.spielArtEnum.$model] : ''" v-model.number="spielArt.spielZeit.$model" step="1" :min="spielArt.spielZeit.$params.minValue.min" type="number"></md-input>
                  <md-icon v-if="spielArt.spielZeit.$dirty && spielArt.spielZeit.$invalid" class="color-invalid">close</md-icon>
                  <span class="md-helper-text">in Minuten</span>
                  <span class="md-error" v-if="!spielArt.spielZeit.required">{{lang.turnierForm.spielzeitRequired}}</span>
                  <span class="md-error" v-else-if="!spielArt.spielZeit.minValue">{{spielArt.spielZeit.$params.minValue.min}} {{lang.turnierForm.spielzeitMinValue}}</span>
                </md-field>
              </div>
            </div>
            <md-button type="submit" class="md-raised md-primary md-filled" @click="setDone('third')">{{lang.editTurnier.buttonSubmit}}</md-button>
          </md-step>
        </md-steppers>
      </form>
    </transition>
    <InformationModal/>
    <SubmitModal/>
  </div>
</template>

<script>
import store from '@/store/index'
import SubmitModal from '../Utils/SubmitModal'
import InformationModal from '../Utils/InformationModal'
import router from '@/router/index'
import {required, minLength, minValue, between, maxLength} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'EditTurnierForm',
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    InformationModal
  },
  props: ['turnierID'],
  created() {
    store.dispatch('getSingleTurnier', this.turnierID);
  },
  data() {
    return {
      SPIELART: store.getters.getSpielArt,
      SPIELMODUS: store.getters.getSpielModus,
      active: 'first',
      first: false,
      second: false,
      third: false,
      firstStepError: null,
      secondStepError: null,
      thirdStepError: null,
      disabledDates: date => {
        let today = new Date();
        today.setDate(today.getDate()-1)
        return date < today;
      }
    }
  },
   validations() {
    if (this.isGruppensystem) {
      return {
        turnier: {
          turnierName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(28)
          },
          startZeit: {
            required
          },
          stadt: {
            required
          },
          passwort: {
            required,
            minLength: minLength(2)
          },
          anzTeams: {
            required,
            between: between(4, 1024)
          },
          anzGruppen: {
            required,
            minValue: minValue(2)
          },
          anzTische: {
            required,
            minValue: minValue(1)
          },
          pausenZeiten: {
            required,
            minValue: minValue(0)
          },
          spielArtTurnier: {
            $each: {
              spielArtTurnierSchluessel: {
                  spielArtID: {},
                  turnierID: {}
              },
              spielArt: {
                  spielArtID: {},
                  spielArtEnum: {}
              },
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            }
          }
        }
      }
    }
    else {
      return {
        turnier: {
          turnierName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(28)
          },
          startZeit: {
            required
          },
          stadt: {
            required
          },
          passwort: {
            required,
            minLength: minLength(2)
          },
          anzTeams: {
            required,
            between: between(4, 1024)
          },
          anzTische: {
            required,
            minValue: minValue(1)
          },
          pausenZeiten: {
            required,
            minValue: minValue(0)
          },
          spielArtTurnier: {
            $each: {
              spielArtTurnierSchluessel: {
                  spielArtID: {},
                  turnierID: {}
              },
              spielArt: {
                  spielArtID: {},
                  spielArtEnum: {}
              },
              spielZeit: {
                required,
                minValue: minValue(0)
              }
            }
          }
        }
      }
    }
  },
  methods: {
    validateForm() {
      this.$v.turnier.$touch()
      if (!this.$v.turnier.$invalid) {
        this.editTurnier()
      }
      else {
        this.showDialogActive(false, this.lang.error.formValidationHeadline, this.lang.error.formValidationMessage)
      }
    },
    getValidationClass(fieldName) {
      return {
        'md-invalid': this.isFieldInvalid(fieldName)
      }
    },
    isFieldInvalid(fieldName) {
      const field = this.$v.turnier[fieldName]
      if (field) {
        return field.$invalid && field.$dirty;
      }
    },
    async editTurnier() {
      if (this.isOffline) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
      } else {
        if (!this.isGruppensystem) {
          this.turnier.anzGruppen = null;
        }
        await store.dispatch('putTurnier', this.turnier)
          .then(result => {
            if (result.data != null) {
              this.showDialogActive(true, this.lang.editTurnier.successHeadline, "");
              setTimeout(() => {
                store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false);
                router.push({name: 'TurnierDetail', params:{turnierID: result.data.turnierID}}); 
              }, store.state.submitModalSuccessSeconds);
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage)
            }
          })
          .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage)
          });
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
    setDone(id, index) {
      this[id] = true
      this.secondStepError = null
      if (index) {
        this.active = index
      }
    },
    binomial(n, k) {
      if ((typeof n !== 'number') || (typeof k !== 'number')) {
        return false; 
      }
      let coeff = 1;
      for (var x = n-k+1; x <= n; x++) {
        coeff *= x; 
      }
      for (x = 1; x <= k; x++) {
        coeff /= x;
      } 
      return coeff;
    },
    showHinweis() {
      store.commit("SET_SHOW_INFORMATION_DIALOG", {
        show: true,
        headline: this.lang.turnierForm.spielZeitenHinweisHeadline,
        message: (this.isGruppensystem ? this.lang.turnierForm.spielZeitenHinweisGruppen : this.lang.turnierForm.spielZeitenHinweisKO) + ' ' + this.lang.turnierForm.spielZeitenHinweis
      });
    }
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    turnier() {
      return store.getters.getTurnier;
    },
    spielmodusEmpfehlung() {
      if (this.turnier.anzTeams < 3 || this.turnier.anzTeams == null) {
        return this.lang.turnierForm.spielModusNull;
      } else {
        return this.turnier.anzTeams > 6 ? this.lang.turnierForm.spielModusGruppen : this.lang.turnierForm.spielModusKO;
      }
    },
    anzSingleSpiele() {
      if (this.turnier.anzTeams < 2 || this.turnier.anzTeams == null) {
        return 0;
      } else {
        return this.binomial(this.turnier.anzTeams, 2);
      }
    },
    anzSingleSpieleTeam() {
      if (this.turnier.anzTeams < 2 || this.turnier.anzTeams == null) {
        return 0;
      } else {
        return this.turnier.anzTeams-1;
      }
    },
    anzTeamsFirstFinalGame() {
      if (this.turnier.anzGruppen == null || this.turnier.anzGruppen < 2) {
        return 0;
      }
      let anzteamsFinalSpiel1 = this.turnier.anzGruppen * (this.turnier.gruppenPhaseZweiterWeiter ? 2 : 1);
      if (anzteamsFinalSpiel1 < 2) {
        return 0;
      }
      let exponent = 0;
      do {
          if (anzteamsFinalSpiel1 > Math.pow(2, exponent) && anzteamsFinalSpiel1 < Math.pow(2, exponent + 1)) {
              anzteamsFinalSpiel1 = Math.pow(2, exponent + 1);
          }
          exponent += 1;
      } while (anzteamsFinalSpiel1 != Math.pow(2, exponent))

      return anzteamsFinalSpiel1;
    },
    gesamtSpielzeit() {
      const defaultSpielZeit = 15;
      let spielZeitGesamt = 0;

      if (this.turnier.spielArtTurnier == undefined || this.turnier.spielArtTurnier.length == 0) {
        return "";
      }

      const spielArtTurnier = this.turnier.spielArtTurnier.map(s => {
        return {
          spielArt: s.spielArt.spielArtEnum,
          countTeams: Object.values(this.SPIELART).find(t => t.name == s.spielArt.spielArtEnum).countTeams,
          spielZeit: s.spielZeit
        }
      })

      if (this.isGruppensystem) {
        const anzTeamsWeiter = this.anzTeamsFirstFinalGame;
        if (anzTeamsWeiter < 4 || this.anzSpieleProGruppe == 0 || this.turnier.anzGruppen == 0 || this.turnier.anzTische == 0) {
          return "";
        }
        const exponent = (Math.log(anzTeamsWeiter) / Math.log(2));
        const spielZeitPlatz3 = spielArtTurnier.find(s => s.spielArt === this.SPIELART.FINALE_PLATZ3.name);
        let spielzeitFinalPhase = spielZeitPlatz3 == undefined ? 0 : spielZeitPlatz3.spielZeit;
        spielzeitFinalPhase = spielzeitFinalPhase == 0 ? defaultSpielZeit + this.turnier.pausenZeiten : spielzeitFinalPhase + this.turnier.pausenZeiten;
        for (let i = exponent; i > 0; i--) {
          const anzTeamsFinalSpielArt = Math.pow(2, i);
          const anzSpieleFinalSpielArt = anzTeamsFinalSpielArt/2;
          const anzTische = this.turnier.anzTische > anzSpieleFinalSpielArt ? anzSpieleFinalSpielArt : this.turnier.anzTische;
          const spielArt = spielArtTurnier.find(s => s.countTeams == anzTeamsFinalSpielArt);
          const spielArtSpielZeit = spielArt == undefined ? 0 : spielArt.spielZeit;
          spielArtSpielZeit = spielArtSpielZeit == 0 ? defaultSpielZeit : spielArtSpielZeit;
          anzSpieleFinalSpielArt = Math.floor(anzSpieleFinalSpielArt/anzTische) + (anzSpieleFinalSpielArt%anzTische)
          spielzeitFinalPhase += (anzSpieleFinalSpielArt*(spielArtSpielZeit+this.turnier.pausenZeiten));
        }
        //passt nicht zu 100%, wenn Teamanzahl über die Gruppen ungleich
        const anzTische = this.turnier.anzTische > this.turnier.anzGruppen ? this.turnier.anzGruppen : this.turnier.anzTische;
        const tischeProGruppe = Math.floor(this.turnier.anzGruppen/anzTische) + (this.turnier.anzGruppen%anzTische);
        const anzSpieleGruppenPhase = this.anzSpieleProGruppe * tischeProGruppe;
        const spielArtGruppenSpiel = spielArtTurnier.find(s => s.spielArt == this.SPIELART.GRUPPENSPIEL.name);
        let spielZeitGrupppenSpiel = spielArtGruppenSpiel == undefined ? 0 : spielArtGruppenSpiel.spielZeit;
        spielZeitGrupppenSpiel = spielZeitGrupppenSpiel == 0 ? defaultSpielZeit : spielZeitGrupppenSpiel;
        const spielZeitGruppenPhase = anzSpieleGruppenPhase * (spielZeitGrupppenSpiel+this.turnier.pausenZeiten);
        spielZeitGesamt = spielZeitGruppenPhase + spielzeitFinalPhase;
      } else {
        if (this.anzSingleSpiele == 0 && this.turnier.anzTische < 1) {
          return "";
        }
        const spielArtEinzelSpiel = spielArtTurnier.find(s => s.spielArt == this.SPIELART.EINZELSPIEL.name);
        let spielZeitEinzelSpiel = spielArtEinzelSpiel == undefined ? 0 : spielArtEinzelSpiel.spielZeit;
        spielZeitEinzelSpiel = spielZeitEinzelSpiel == 0 ? defaultSpielZeit : spielZeitEinzelSpiel;
        const anzTische = this.turnier.anzTische > 3 ? 2 : this.turnier.anzTische;
        spielZeitGesamt = (this.anzSingleSpiele * (spielZeitEinzelSpiel + this.turnier.pausenZeiten))/anzTische;
      }
      const spielZeitGesamtStunden = Math.floor(spielZeitGesamt / 60);
      const spielZeitGesamtMinuten = spielZeitGesamt % 60;
      return (spielZeitGesamtStunden > 0 ? `${spielZeitGesamtStunden}h` : '') + ' ' + (spielZeitGesamtMinuten > 0 ? `${spielZeitGesamtMinuten}min`: '');
    },
    anzSpielerProGruppeEmpfehlung() {
      if (this.turnier.anzTeams < 4 || this.turnier.anzTeams == null) {
        return 0;
      } else {
        return Math.round(this.turnier.anzTeams/4);
      }
    },
    anzSpielerProGruppe() {
      if (this.turnier.anzTeams == 0 || this.turnier.anzTeams == null || this.anzSpielerProGruppeEmpfehlung == 0) {
        return 0;
      } else if (this.turnier.anzGruppen == 0 || this.turnier.anzGruppen == null) {
        return Math.round(this.turnier.anzTeams/this.anzSpielerProGruppeEmpfehlung);
      }
      else {
        return Math.round(this.turnier.anzTeams/this.turnier.anzGruppen);
      }
    },
    anzSpieleProGruppe() {
      if (this.anzSpielerProGruppe == 0) {
        return 0;
      } else {
        return this.binomial(this.anzSpielerProGruppe, 2);
      }
    },
    isTurnierNotStarted() {
      return store.getters.isTurnierStatusErstellt || store.getters.isTurnierStatusTurnierPlanErstellt;
    },
    isFinalphaseGestartet() {
      return store.getters.isFinalphaseGestartet;
    },
    isGruppensystem: {
      get: function () {
        if (this.turnier.spielmodus == undefined) {
          return true;
        } else {
          return this.turnier.spielmodus == this.SPIELMODUS.GRUPPEN_SYSTEM.name
        }
      },
      set: function (newValue) {
        this.turnier.spielmodus = newValue ? this.SPIELMODUS.GRUPPEN_SYSTEM.name : this.SPIELMODUS.KO_SYSTEM.name
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
